body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap');

.toast-success-container {
  color: #000 !important;
  border-radius: 8px !important;
  background: #ffffff !important;
  border: 1px solid #3388F3 !important;
  box-shadow: 0px 1px 5px rgba(248, 175, 175, 0.1) !important;
}

.toast-success-container-after {
  overflow: hidden;
  position: relative;
}

.toast-success-container-after::after {
  top: 0;
  left: 0;
  content: '';
  width: 7px;
  height: 100%;
  position: absolute;
  display: inline-block;
  background-color: #3388F3;  /* Adjust the color as needed */
}

.card-shadow {
  transition: box-shadow 0.3s ease-in-out;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1); 
}

.card-shadow:hover {
  box-shadow: 10px 0px 15px rgba(59, 130, 246, 0.4), -10px 0px 15px rgba(59, 130, 246, 0.4);
}
